<template>
  <validation-provider
    :rules="{ ...rules, replacementWorkPlace: isValidateWorkPlaceEnabled }"
    :name="`«${name}»`"
    v-slot="v"
    tag="div"
    :vid="vid"
  >
    <div :class="{ 'hm-form__element-wrapper': true }">
      <div class="hm-form__element-label">
        {{ name }}
        <span v-if="required">*</span>
      </div>
      <employee-replacement
        v-model="localValue"
        :replace-direction="replaceDirection"
        :show-employee="showEmployee"
        :show-placement-type="showPlacementType"
        :show-placement-number="showPlacementNumber"
        :room-choice-trouble="roomChoiceTrouble"
        :address-choice-trouble="addressChoiceTrouble"
        :not-to-validate-relocation-data="notToValidateRelocationData"
        :has-error="!!v.errors.length"
        @on-workplaces-change="setWorkPlaces"
        @change="onInput"
      />
      <div v-if="v.errors.length || hintText" class="hm-form__element-hint hm-form__element-hint--error">
        {{ v.errors.length ? v.errors[0] : hintText }}
      </div>
    </div>
  </validation-provider>
</template>

<script>
import uid from '@/components/hm-form/mixins/uid';
import EmployeeReplacement from './EmployeeReplacement.vue';

export default {
  name: 'HmEmployeeReplacement',
  mixins: [uid],
  components: { EmployeeReplacement },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    name: {
      type: String,
      default: undefined,
    },
    hintText: {
      type: String,
      default: undefined,
    },
    replaceDirection: {
      type: String,
      default: '',
    },
    showEmployee: {
      type: Boolean,
      default: false,
    },
    showPlacementType: {
      type: Boolean,
      default: false,
    },
    showPlacementNumber: {
      type: Boolean,
      default: false,
    },
    roomChoiceTrouble: {
      type: Boolean,
      default: false,
    },
    addressChoiceTrouble: {
      type: Boolean,
      default: false,
    },
    notToValidateRelocationData: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localValue: this.value,
      workPlaces: [],
    };
  },
  computed: {
    isValidateWorkPlaceEnabled() {
      return this.showPlacementNumber && !!this.workPlaces.length;
    },
  },
  methods: {
    setWorkPlaces(places) {
      this.workPlaces = places;
    },
    onInput(val) {
      this.$emit('input', val);
    },
  },
};
</script>
